@media (min-width: 768px) {
    .visor-wall {
        margin-left: 250px;
    }

    .uv-prompt{
        top: 44% !important;
        left: 52% !important;
    }

    .uv-install-prompt{
        top: 40% !important;
        left: 46% !important;
    }

    h3.dashboard-title {
        display: block !important;
    }
}
.flex {
    display: flex;
}
.chatfeed-order-switch{
    display: flex;
    margin-left: 2rem;
}
.chatfeed-order-switch p{
  margin: 0 1rem;
}

.uv-install-prompt{
    text-align: center;
    width: 400px;
    font-weight: 600;
    border-radius: 0.2rem;
    background-color: rgba(0, 0, 0, 0.95);
    color: white;
    position: fixed;
    top: 40%;
    left: 25%;
}

.uv-install-prompt:hover {
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.05);
}

.uv-install-prompt.uv-login-prompt img {
    margin-top: 15px;
}

.uv-login-prompt{
    cursor: pointer;
}

.uv-login-prompt p, .uv-avatar p{
    margin-left: 0.5rem;
}


.uv-border{
    display: flex;
    border: 1px solid black;
    font-weight: 600;
    border-radius: 0.2rem;
    background-color: rgba(0, 0, 0, 0.95);
    padding: 8px;
    color: white;
}


.uv-avatar {
    display: flex;
    border: 1px solid black;
    font-weight: 600;
    border-radius: 0.2rem;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 8px;
    color: white;
}

.uv-avatar svg{
   display: block;
   margin: auto;

}

.visor-wall{
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(50, 50, 50, 0.96);
    z-index: 5000;
}
.spinner{
    margin: auto 1rem;
    /* position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 5000; */
}
.spinner svg{
    display: block;
    margin: auto;
    /* position: fixed;
    top: 50%;
    left: 50%;
    z-index: 5001; */
}
.avatar-wrapper{
    display: flex;
    /* margin: auto; */
}

.visor-wall .wrapper{
    height: 100%;
    width: 100%;
    position: relative;
}

.uv-prompt{
    position: fixed;
    width: 214px;
    top: 50%;
    left: 31%;
}

.uv-prompt:hover {
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.05);
}

.MuiToolbar-root.MuiToolbar-regular {
    padding-top: 0.35rem;
}

/* Sidebar */

@media (min-width: 768px) {
    img.sidebar-logo {
        height: 100px !important;
        margin-bottom: 0px !important;
    }
}

img.sidebar-logo {
    height: 75px;
    margin: 25px;
}

h3.dashboard-title {
    display: none;
    color: black;
}

.makeStyles-listRoot-3 {
    margin-top: 0px !important;
}
ul.MuiList-root.makeStyles-listRoot-3.MuiList-padding {
    padding-left: 3px;
}

/* Admin Panel page */
.error-message{
    color: red;
    margin: 0.5rem auto 0 auto;
}

.admin-panel-submit {
    width: 100%;
    margin-bottom: 1.1rem !important;
}


/* Profile page */
.top-overlay {
    /* opacity: 0.6; */
    background: linear-gradient(87deg,#11cdef,#1171ef);
}
.profile-grid-box {
    margin-top: -15rem !important;
    margin-left:auto !important;
    margin-right: auto !important;
}

.profile-sigil-box div  {
    box-shadow: none !important;
    padding-bottom: 20px;
}

.profile-sigil-box div svg {
    margin: auto !important;
    border-radius: 23px;
    margin-bottom: 25px !important;
}


/* Ship Activity Page */
.MuiCardContent-root:last-child {
    padding-bottom: 0px !important;
}
.chat-feed{
    height: 793px;
}
.terminal-feed{
    height: 440px;
}
.feed-container{
    overflow-y: scroll;
    overflow-x: hidden;
}
.subscription-feed-container{
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
}
/* custom */
.mb-1rem{
    margin-bottom: 1rem;
}

.terminal-button{
    cursor:default !important;
    opacity:1 !important;
    background-color: #233dd2 !important;
}
.terminal-button:hover{
    transform:none !important;
}
.chat-channel{
    vertical-align: top !important
}
.chat-message{
    white-space: normal !important;
}